.ui-vpp-highlighted-specs__compats {
  width: 100%;
}

.compats-specs__card {
  border-radius: 0 0 $border-radius-6 $border-radius-6;
}

.compats-specs__list {
  list-style-type: none;

  .compats-specs__item-list {
    position: relative;
    padding-left: $andes-spacing-8;

    &::before {
      position: absolute;
      left: 0;
      content: '\2022';
    }

    &:first-child {
      margin-top: 10px;
      margin-bottom: $andes-spacing-8;
    }
  }
}

.ui-pdp-seller__compats,
.ui-pdp-compats__compats {
  .ui-compats-header__container-message {
    .andes-message--quiet {
      background: color(color_black_04);

      .andes-message__buttons-container {
        /* stylelint-disable-next-line selector-no-qualifying-type */
        button.andes-button.andes-message__secondary-button.andes-button--transparent {
          background: $andes-blue-150;
          color: $andes-blue-500;
        }
      }
    }
  }
}

.ui-pdp-compats--message {
  margin-bottom: $andes-spacing-12;
}

.ui-pdp-compats--message.compats-message-visible {
  position: relative;
}

.compats-message-hidden {
  display: none;
  animation: messageAnimationOut 0.7s linear 0s 1 normal forwards;
}

.compats-message-visible {
  display: block;
  animation: messageAnimationIn 0.7s linear 0s 1 normal forwards;
}

.compats-specs__action-button-specs {
  &:hover {
    background: $andes-gray-040-solid;
  }

  .andes-button__content {
    gap: 4px;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.specs_component_message-visible {
  animation: colapseAnimationVisible 2s ease-in-out forwards;
  height: 100%;
}

/* stylelint-disable-next-line selector-class-pattern */
.specs_component_message-hidden {
  height: 75px;
  overflow: hidden;
  position: relative;
  animation: none;
}

/* stylelint-disable-next-line selector-class-pattern */
.specs_component_transparent {
  position: absolute;
  height: 31px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, color(color_black_1000) -8.81%, #f5f5f5 80%);
}

@keyframes messageAnimationIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    display: block;
  }
}
@keyframes messageAnimationOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes colapseAnimationVisible {
  0% {
    height: 75px;
  }

  100% {
    height: 100%;
  }
}
@keyframes colapseAnimationHidden {
  0% {
    height: 100%;
  }

  100% {
    height: 75px;
    overflow: hidden;
    position: relative;
  }
}
