// VPP Common
@import '../../../../styles/common';

// Andes Shared for VIP CORE
@import './vip.core.andes';

// Andes styles for VIP CORE Desktop
// Reservado para estilos de andes que no sean de vip core mobile

// Recos FE
@import '~@recommendations-fe/carousel-snapped/src/styles/recommendations-fe-carousel-snapped.desktop';
@import '~@recommendations-fe/combos/src/styles/desktop';
@import '~@recommendations-fe/list/src/styles/recommendations-fe-list.desktop';
@import '~@recommendations-fe/shops/src/styles/recommendations-fe-shops.desktop';
@import '~@recommendations-fe/card/src/styles/recommendations-fe-card-horizontal.desktop';
@import '~@recommendations-fe/card/src/styles/recommendations-fe-card-vertical.desktop';
@import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-horizontal--for-carousel-snapped';
@import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-horizontal--for-list-horizontal';
@import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-horizontal--for-list-pads';
@import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-vertical--for-carousel-snapped';

// Seo Frontend Components
@import '~@seo-frontend-components/extended-menu/index';

// Polycard
@import '~@polycard/phrase/build';
@import '~@polycard/card/build';

// UI Comps web
@import '~@ui-components-web/ml-compats/src/styles/desktop';

// UI Reviews
@import '~@ui-reviews/ui-reviews-capability/src/styles/reviews.desktop';

// CPG Nordic
@import '~cpg-nordic/src/styles/cpg.desktop';

// EShops Components Library
@import '~eshops-components-library/src/components/ProfileHeader/lib/desktop';
@import '~eshops-components-library/src/components/BottomSheet/index';

// VIP CORE Common
@import './vip.core';

// VIP Internal imports
@import '../../../../components/thumbnail/thumbnail.desktop';
@import '../../../../components/action-modal/styles/action-modal.desktop';
@import '../../../../components/gallery/styles/gallery.desktop';
@import '../../../../components/price/styles/price.desktop';
@import '../../../../components/generic-summary/generic-summary.desktop';
@import '../../../../components/buybox/styles/buybox.desktop';
@import '../../../../components/grouped-share-bookmark/grouped-share-bookmark';
@import '../../../../components/gift-registry/styles/gift-registry.desktop';
@import '../../../../components/buybox/actions/styles/action-buybox.desktop';
@import '../../../../components/seller-reputation-info/styles/seller-reputation';
@import '../../../../components/seller-reputation-info/styles/seller-reputation-vip';
@import '../../../../components/reviews/styles/reviews.desktop';
@import '../../../../components/header/styles/header.desktop';
@import '../../../../components/review-summary/styles/review-summary.desktop';
@import '../../../../components/share/share.desktop';
@import '../../../../components/share/share-content.desktop';
@import '../../../../components/bookmark/styles/bookmark.desktop';
@import '../../../../components/iframe-reviews/styles/iframe-reviews.desktop';
@import '../../../../components/description/styles/description.desktop';
@import '../../../../components/box-component/styles/box-component.desktop';
@import '../../../../components/box-component/styles/box.desktop';
@import '../../../../components/variations/styles/variations.desktop';
@import '../../../../components/variations/styles/variations-dropdown';
@import '../../../../components/breadcrumb/styles/breadcrumb';
@import '../../../../components/store-header/styles/store-header.desktop';
@import '../../../../components/component-list/component-list';
@import '../../../../components/related-searches/styles/related-searches.desktop';
@import '../../../../components/specs/specs.desktop';
@import '../../../../components/specs/spec-groups-collapsable';
@import '../../../../components/action-collapsable/styles/action-collapsable.desktop';
@import '../../../../components/list/list.desktop';
@import '../../../../components/seller-info-label/styles/seller-info.desktop';
@import '../../../../components/seller-info/styles/seller-info';
@import '../../../../components/seller-data/styles/seller-data';
@import '../../../../components/seller-data/styles/banner';
@import '../../../../components/seller-data/styles/header';
@import '../../../../components/seller-data/styles/status';
@import '../../../../components/seller-data/styles/footer';
@import '../../../../components/buybox/payment/styles/payment-sprites';
@import '../../../../components/payment-methods/styles/payment-methods.desktop';
@import '../../../../components/questions/styles/questions.desktop';
@import '../../../../components/qadb/styles/qadb.desktop';
@import '../../../../components/grouped-header/styles/grouped-header';
@import '../../../../components/onboarding/styles/onboarding.desktop';
@import '../../../../components/sell-your-item/sell-your-item';
@import '../../../../components/edit-your-item/edit-your-item';
@import '../../../../components/tooltip/tooltip.desktop';
@import '../../../../components/tooltip/tooltip';
@import '../../../../components/apparel-size-specs/styles/apparel-size-specs.desktop';
@import '../../../../components/apparel-size-specs/styles/fit-as-expected.desktop';
@import '../../../../components/apparel-size-specs-preview/styles/apparel-size-specs-preview.desktop';
@import '../../../../components/card-tooltip/styles/card-tooltip.desktop';
@import '../../../../components/tooltip/components/styles/information-tooltip-content';
@import '../../../../components/denounce/styles/denounce.desktop';
@import '../../../../components/advertising/styles/advertising.desktop';
@import '../../../../components/redirected-from/redirected-from';
@import '../../../../components/compatibilities/styles/compatibilities';
@import '../../../../components/highlighted-specs/styles/highlighted-specs.desktop';
@import '../../../../components/specs/striped-specs';
@import '../../../../components/data-display/discrete-bar/styles/discrete-bar';
@import '../../../../components/data-display/progress-bar/styles/progress-bar';
@import '../../../../components/merch-ecosystem/styles/merch-ecosystem';
@import '../../../../components/sticky-wrapper/styles/sticky-wrapper.desktop';
@import '../../../../components/buybox/cart-actions/styles/cart-actions.desktop';
@import '../../../../components/promotions/styles/promotions';
@import '../../../../components/moq-information/styles/moq-information.desktop';
@import '../../../../components/stock-information/styles/stock-information.desktop';
@import '../../../../components/tooltip-legal/styles/tooltip-legal.desktop';
@import '../../../../components/compats-widget/styles/compats-widget.main.desktop';
@import '../../../../components/header-top-brand/styles/header.desktop';
@import '../../../../components/header-brand-logo/styles/header-brand-logo.desktop';
@import '../../../../components/short-description-message/styles/short-description-message';
@import '../../../../components/official-stores/styles/carousel.desktop';
@import '../../../../components/coupons/styles/coupons.desktop';
@import '../../../../components/coupons/styles/coupons-vip.desktop';
@import '../../../../components/coupons-awareness/styles/coupons-awareness.desktop';
@import '../../../../components/on-demand-iframe/styles/on-demand-iframe.desktop';
@import '../../../../components/trade-in/styles/trade-in.desktop';
@import '../../../../components/preload-iframe/styles/index.desktop';
@import '../../../../components/eshops/styles/eshops.desktop';
@import '../../../../components/recommendations/styles/polycard-overrides.desktop';
@import '../../../../components/buybox/banner/styles/banner';
@import '../../../../components/buybox/banner/styles/banner-vip';
@import '../../../../components/advertising-adn/styles/billboard-context.desktop';
@import '../../../../components/installation-service/styles/installation-widget.desktop';
@import '../../../../components/extended-menu/styles/extended-menu.desktop';

// Recos overrides Polycard
@import '~@recommendations-fe/commons/src/styles/overrides/polycard_card-list';
@import '~@recommendations-fe/commons/src/styles/overrides/polycard_card-grid';
@import '~@recommendations-fe/list/src/styles/overrides/polycard_list-pads';
@import '~@recommendations-fe/carousel-snapped/src/styles/overrides/polycard_snapped-horizontal';
@import '~@recommendations-fe/list/src/styles/overrides/polycard_list-horizontal';

// Recos FE override for shops themes
@import '../../../../components/recommendations/styles/shops-themes-overrides.desktop';

$max-container-width: 96%;

html,
body {
  min-width: 1160px;
}

// TODO: validate with Andes or Nordic about this class.
.scroll-disabled {
  height: 100%;
  overflow: auto;
}

.#{$pdp-prefix}-container__row--reverse {
  flex-direction: row-reverse;
}

.#{$vip-core-prefix} {
  width: $pdp-layout-width;

  &-container--head {
    position: relative;

    &:empty {
      margin-top: $layout-padding-40;
    }

    .#{$pdp-prefix}-container__row {
      &--item-status:not(:empty) {
        margin-top: 8px;
      }
    }
  }

  .ui-vip-compats--dark {
    margin-bottom: 25px;
  }

  .ui-vip-compats--head {
    margin-bottom: $andes-spacing-12;
  }

  &-container--short-description {
    .#{$pdp-prefix}-container__row {
      &--meliplus-label-summary {
        margin-top: $andes-spacing-12;
        margin-bottom: $andes-spacing-12;
      }

      &--variations:not(:empty) {
        margin-top: $andes-spacing-24;
      }

      &--cpg-summary:not(:empty) {
        margin-top: 20px;

        .#{$pdp-prefix}-color--BLACK.#{$pdp-prefix}-media__text {
          margin-top: 2px;
        }

        .#{$pdp-prefix}-color--GRAY.#{$pdp-prefix}-media__text+ // eslint-disable-line

        .#{$pdp-prefix}-color--BLACK.#{$pdp-prefix}-media__text {
          margin-top: 8px;
        }
      }

      &--payment-summary:not(:empty) {
        margin-top: 20px;
      }

      &--cbt-summary:not(:empty) {
        margin-top: 26px;
      }

      &--shipping-summary:not(:empty),
      &--second-shipping-summary:not(:empty),
      &--meliplus-summary:not(:empty),
      &--upselling-meliplus-summary:not(:empty),
      &--returns-summary:not(:empty),
      &--pick-up-summary:not(:empty) {
        margin-top: $andes-spacing-12;
      }

      &--coupon-summary:not(:empty) {
        margin-top: 20px;
      }

      &--cbt-taxes-summary:not(:empty) {
        margin-top: 20px;
      }

      &--cbt-taxes-fc-us-summary:not(:empty) {
        margin-top: 5px;
      }

      &--item-status:not(:empty) {
        margin-top: 20px;
      }

      &--top-available-quantity-summary {
        margin: $andes-spacing-24 0 0 0;

        & svg {
          width: 15px;
          height: 12px;
          top: 2px;
          margin-right: $andes-spacing-4;
        }

        & .ui-pdp-action-modal__link {
          font-weight: 600;
        }
      }

      &--available-quantity:not(:empty) {
        margin-top: 16px;

        .andes-button {
          line-height: 1;
          border: 0;
        }

        // sass-lint:disable bem-depth //
        .ui-pdp-buybox__quantity__messages__message:not(:empty) {
          margin: 0;
          margin-top: 8px;
        }
      }

      &--paypal-tooltip:not(:empty) {
        margin-top: 24px;
      }

      &--main-actions:not(:empty) {
        margin-top: 26px;
      }

      &--cart-actions:not(:empty) {
        margin-top: 0;
      }

      &--shipping-cart-benefits-tooltip:not(:empty) {
        margin-top: 24px;
      }

      &--credits-tooltip:not(:empty) {
        margin-top: 24px;
      }

      &--loyalty-tooltip:not(:empty) {
        margin-top: 24px;
      }

      &--buy-benefits:not(:empty) {
        margin-top: $andes-spacing-24;
      }

      &--item-status-short-description-message {
        margin-top: 16px;
      }

      &--stock-and-full {
        .#{$pdp-prefix}-fetching-component:first-child {
          margin-top: $andes-spacing-24;
        }

        .#{$pdp-prefix}-container__row {
          &--stock-information:first-child:not(:empty) {
            margin-top: $andes-spacing-24;
          }

          &--fulfillment-information {
            margin: 0;

            &:first-child:not(:empty) {
              margin-top: $andes-spacing-24;
            }

            .andes-tooltip__trigger:hover {
              cursor: pointer;
            }

            .ui-pdp-icon--full {
              top: 3px;
              width: 50px;
              height: 15px;
            }

            .ui-pdp-icon--full-super {
              width: fit-content;
              height: fit-content;
            }

            .ui-pdp-icon--tooltip {
              fill: $andes-blue-500;
            }
          }

          .ui-pdp-promotions-pill-label--with-icon {
            padding: 0;
          }
        }
      }
    }
  }

  &-container--content-left {
    margin-left: $andes-spacing-24;
    width: 760px;

    .#{$pdp-prefix}-container__row--compatibilities + .#{$pdp-prefix}-container__row--technical-specifications {
      border-top: none;
      padding-top: 20px;
    }

    .ui-recommendations-carousel-snapped {
      .andes-carousel-snapped__container {
        max-width: 720px;
      }
    }

    .#{$pdp-prefix}-carousel-snapped-up {
      border-top: 1px solid $andes-gray-100;
      padding: 40px 0;

      &.no-border {
        border-top: none;
      }
    }

    :not(:first-child) .ui-pdp-carousel-snapped-up {
      border-top: none;
    }

    .#{$pdp-prefix}-combos {
      border-top: 1px solid $andes-gray-100;
      padding: 40px 0 0;
    }

    .#{$pdp-prefix}-container__row {
      &--compatibilities {
        border-top: 1px solid $andes-gray-100;
        padding-top: 40px;
      }

      &--technical-specifications {
        border-top: 1px solid $andes-gray-100;
        padding-top: 40px;
        padding-bottom: 40px;
      }

      &--description {
        border-top: 1px solid $andes-gray-100;
        padding-top: 40px;
        padding-bottom: 40px;
      }

      &--apparel-size-specs-preview {
        border-top: 1px solid $andes-gray-100;
        padding-top: 40px;
        padding-bottom: 40px;
      }

      &--questions {
        border-top: 1px solid $andes-gray-100;
        padding-top: 40px;
      }

      &--reviews {
        border-top: 1px solid $andes-gray-100;
        margin-top: 40px;
        padding-top: 40px;
      }

      &--highlighted-specs-attrs {
        border-top: 1px solid $andes-gray-100;
        padding-top: 40px;

        .#{$pdp-prefix}-container__row--technical-specifications {
          border-top: none;
          padding-top: 0;
        }

        section {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .#{$pdp-prefix}-vis-carousel-b2c {
      border-top: 1px solid $andes-gray-100;
      margin-top: $andes-spacing-32;
      padding: $andes-spacing-40 $andes-spacing-32 $andes-spacing-16 $andes-spacing-16;
    }
  }

  .#{$pdp-prefix}-container--column-right {
    width: 376px;
    max-width: 376px;
    margin-left: $andes-spacing-24;

    .#{$vip-prefix}-content-right {
      .#{$pdp-prefix}-container__row:not(:first-child) {
        border-top: 1px solid $andes-gray-100;
      }

      .#{$pdp-prefix}-container__row--advertising-vip-right-mshops {
        width: 100%;
      }
    }

    .ui-vpp-payment-promotion {
      &__action_button_chevron {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .#{$vip-prefix}-content-right-ads {
      border: none;
      padding: 0;
      margin-top: 8px;

      .#{$pdp-prefix}-component-list__item {
        &--desktop-content-right-ads {
          border: none;
        }
      }
    }
  }

  &-container--column__right {
    position: relative;
    width: 100%;
  }

  .#{$pdp-prefix} {
    &-container--top {
      margin-bottom: $andes-spacing-12;
    }

    &-container--pdp {
      border-radius: 4px;
    }

    &-component-list {
      &__item {
        &--desktop-head {
          .#{$pdp-prefix}-message {
            margin-top: $andes-spacing-8;
            margin-bottom: $andes-spacing-12;
          }
        }
      }
    }

    &-seller {
      margin-top: 20px;

      &__header {
        margin-bottom: 0;

        &__image-container {
          align-items: center;
        }

        &__info-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        &__title {
          margin-top: 0;
        }
      }

      &__icon-description {
        height: 28px;
      }

      &__item-description {
        width: 100%;
      }

      &__text-description {
        width: 100%;
      }

      &__header--margin {
        margin-bottom: 18px;
      }

      &__link-trigger {
        display: inline-block;
        margin-top: 0;
      }
    }
  }

  .#{$pdp-prefix}-actions {
    .#{$pdp-prefix}-action-primary--disabled,
    .#{$pdp-prefix}-action-secondary--disabled {
      cursor: not-allowed;
    }
  }

  .shops-pdp-container {
    .#{$vip-core-prefix}-container--content-left {
      margin-left: 50px;
      width: 718px;
    }

    .#{$pdp-prefix}-container--column-right {
      margin-left: $andes-spacing-48;
    }

    .#{$pdp-prefix} {
      &-gallery {
        min-height: 500px;

        &__column {
          margin-left: $andes-spacing-16;
          margin-top: $andes-spacing-16;

          &__variation-picture {
            left: 56px;
            width: 700px;
          }
        }

        &__figure {
          width: 668px;
          height: 500px;
          margin: 0 $andes-spacing-16;
          padding: $andes-spacing-16 0;

          &__image {
            padding-top: $andes-spacing-24;
          }
        }

        .#{$pdp-prefix}-thumbnail {
          &__picture {
            width: $andes-spacing-48;
            height: $andes-spacing-48;
          }
        }
      }

      &-gallery__zoom {
        &-container {
          height: 412px;
          width: 350px;
        }
      }
    }
  }
}

.#{$vip-core-prefix}--official-store {
  width: 100%;

  .#{$pdp-prefix} {
    &-container--top,
    &-container--pdp,
    &-container--top__bottom {
      max-width: $pdp-layout-width;
      margin: 0 auto;
    }

    &-container--top,
    &-container--top__bottom {
      background-color: $andes-bg-primary;
    }

    &-container--pdp {
      margin-top: -60px;
      border-radius: 0 0 4px 4px;
      position: relative;
    }
  }
}

.#{$pdp-prefix} {
  &-bookmark__link-bookmark {
    a {
      position: relative;
      top: auto;
      left: auto;
    }
  }

  &-gallery {
    width: 100%;
    margin-bottom: 68px;

    &__column {
      &__variation-picture {
        left: 56px;
        width: 700px;
      }
    }

    &__figure {
      width: 680px;
    }
  }

  &-gallery__zoom {
    &-container {
      height: 412px;
      width: 350px;

      &--visible {
        display: block;
        border: solid 1px $andes-gray-100;
        box-sizing: content-box;
      }
    }
  }

  &-questions__questions-list {
    margin: 0;
  }

  &-benefits {
    padding: 0;
  }

  &-variations {
    margin-top: 0;
  }

  &-container--share {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 200px;
  }

  &-container--pdp {
    box-shadow: 0 1px 2px 0 $andes-gray-250;
    background-color: $andes-white;
    min-width: 800px;
  }

  &-container--bottom {
    margin-top: 20px;
  }

  &--relative {
    position: relative;
  }

  &-specs {
    flex: 1;

    .ui-pdp-specs__table .andes-table__column:last-of-type {
      width: 73%;
      padding-left: 24px;
    }

    .ui-pdp-list__item + .ui-pdp-list__item {
      margin-left: 50px;
    }

    .#{$pdp-prefix}-list__text {
      word-break: break-word;
      hyphens: auto;
    }
  }

  &-container {
    &--border-left {
      border-right: 1px solid $andes-gray-100;
    }

    &--column-right {
      margin-left: 64px;
      min-width: $pdp-layout-column-right;
    }

    &--border-bottom {
      border-right: 1px solid $andes-gray-100;
    }
  }

  &-component-list + .ui-vip-content-right {
    padding: 0;
  }
}

.#{$pdp-prefix}-iframe-modal {
  max-width: 100%;
  transition: 0.5s cubic-bezier(0.72, 0.04, 0.12, 0.97);
  width: 840px;
  height: 70%;
  max-height: 92%;

  .andes-modal__content {
    padding: 0;
  }

  iframe {
    width: 100%;
    border: 0;
    -webkit-overflow-scrolling: touch;
    transition: 0.5s cubic-bezier(0.72, 0.04, 0.12, 0.97);
  }

  .andes-spinner__mask {
    background-color: $andes-white;
  }
}

.#{$pdp-prefix}-iframe-modal.andes-modal.ui-pdp-action-modal__modal.split-payments-modal.andes-modal--small {
  width: 607px;
  height: 425px;

  .andes-modal__content {
    height: 310px;
  }

  .andes-modal__header {
    padding: $andes-spacing-48 $andes-spacing-48 $andes-spacing-12 $andes-spacing-48;
  }
}

.#{$pdp-prefix}-breadcrumb {
  margin: 0;
}

.ui-vip-recommendations {
  .carousel-container {
    &::after {
      display: none;
    }
  }
}

.#{$vip-core-prefix}--modal {
  background-color: $andes-white;
  width: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0 $andes-spacing-64;
}

.#{$pdp-prefix}-buybox__quantity {
  &__message {
    margin-left: 0;
  }
}

// item pausado row message
.#{$pdp-prefix}-container__row--item-status-message {
  margin-top: 8px;
}

// @TODO en Andes esta con padding 1em por defecto, y no queda muy bien,
// verlo con alguien de Andes y UX pero esto queda como paliativo
.andes-tooltip__content {
  margin-right: 0;
}

.ui-vpp-message {
  width: 100%;
}

.#{$pdp-prefix}-syi {
  &__link {
    vertical-align: initial;
  }
}

// Se sobreescribe regla de pdp
.#{$pdp-prefix}-questions {
  margin-bottom: 0;
}

.seller-tooltip {
  width: 300px;
}

.#{$vpp-prefix}-merch-ecosystem-container + .ui-vip-content-right {
  padding: 0;
}

// change in recos-combos
.ui-vip-combos--desktop::after {
  display: none;
}

.ui-review-capability__container-vpp .ui-review-capability {
  > div:first-child {
    flex-shrink: 1;
  }
}

.video-container__tap {
  display: contents;
}

.ui-vip-core .ui-pdp-container__row--grouped-share-bookmark {
  position: relative;
  top: -9px;
}

.ui-vip-core .ui-pdp-container__row--gift-registry {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.shops-pdp-container--column-left {
  position: relative;
}

.shops__pills {
  font-size: $font-size-20;
}

.ui-pdp-fc-us-taxes.andes-modal--small {
  width: 568px;

  .andes-modal__header {
    padding: $andes-spacing-32 $andes-spacing-32 0;
  }

  .andes-modal__scroll {
    overflow: hidden;
    border-radius: $spacing-6;
  }

  .andes-modal__content {
    height: 50%;
  }
}

.ui-pdp-banner + .ui-pdp-container__row.ui-pdp-container__row--returns-summary {
  margin-top: $andes-spacing-24;
}

.ui-pdp-iframe-modal-top-quantity-summary {
  height: 570px;
  width: 830px;

  .andes-modal__scroll {
    overflow: hidden;
    height: 100%;
  }

  .andes-modal__header {
    padding: 48px 48px 32px !important;
  }

  .andes-modal__content {
    padding: 0 48px;
    height: 100%;

    iframe {
      overflow: hidden;
      height: 100%;
    }
  }

  .andes-modal__close-button path {
    stroke: $andes-gray-250 !important;
  }
}
