@import '../../../../styles/variables';

.#{$pdp-prefix}-actions {
  width: 100%;

  &__container {
    width: 100%;

    .#{$pdp-prefix}-action__card-tooltip {
      margin-top: 26px;
      padding: 0;

      .#{$pdp-prefix}-card-tooltip__card__content {
        align-items: flex-start;
      }
    }
  }

  .andes-tooltip__trigger {
    .andes-button {
      width: 100%;
    }
  }

  .ui-pdp-action--primary {
    margin-top: 0;
  }

  .ui-pdp-action--secondary,
  .ui-pdp-action--tertiary {
    margin-top: $andes-spacing-8;
    margin-bottom: $andes-spacing-8;
  }

  .andes-button {
    .andes-spinner__icon--xsmall {
      width: 18px;
      height: 18px;
    }

    &.andes-button--quiet .andes-spinner .andes-spinner__icon-border {
      border-color: color(color_blue_600);

      &::after {
        background-color: color(color_blue_600);
      }
    }
  }

  .andes-button--fetching-complete .andes-spinner {
    display: none;
  }

  .#{$pdp-prefix}-action-primary--disabled,
  .#{$pdp-prefix}-action-secondary--disabled {
    cursor: not-allowed;
  }
}
